<script>
import Search from '@/pages/search/components/search'
import { GlobalSearch } from '@/pages/search/controllers'
export default {
  extends: Search,
  computed: {
    scopeModes () {
      return [GlobalSearch.LOCAL_MODE]
    }
  },
  data () {
    return {
      initialRoute: this.$route.path
    }
  },
  watch: {
    '$route.path' (route) {
      if (route !== this.initialRoute) {
        this.search.selectAllItems(false)
      }
    }
  }
}
</script>
